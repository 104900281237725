import React from "react";
import {
  CustomSubText,
  ProductButton,
  ProductButtonContainer,
  ProductCard,
  ProductCardContainer,
  ProductCardMainContainer,
  ProductIconContainer,
  ProductIconTextContainer,
  ProductImageContainer,
  ProductName,
  ProductRightSection,
  ProductSubText,
  ProductSubTextContainer,
  ProductTextContainer,
  ProductsMainContainer,
  ProductsMainHeading,
  ProductsTextContainer,
} from "../../../styles/ProductStyling/productsection/styleProductSection";
import { useGetProductsInfo } from "../../../customhooks/ourproducts/useProduct";
import { CircularProgress } from "@mui/material";

const ProductSection = () => {
  const { Products, isLoading, isSuccess } = useGetProductsInfo();
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (isSuccess) {
    return (
      <>
        <ProductsMainContainer>
          <ProductsTextContainer>
            <ProductsMainHeading> STEAM Products</ProductsMainHeading>
            <ProductSubTextContainer>
              <CustomSubText>Know The STEAM Digital Products</CustomSubText>
            </ProductSubTextContainer>
          </ProductsTextContainer>
          <ProductCardMainContainer>
            <ProductCardContainer>
              {Products.map((product) => (
                <ProductCard key={product?.productId}>
                  <ProductImageContainer>
                    <img
                      src={product?.productImage}
                      alt="STEAM Words"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </ProductImageContainer>
                  <ProductRightSection>
                    <ProductName>{product?.productName}</ProductName>
                    <ProductIconTextContainer>
                      <ProductIconContainer>
                        <ProductTextContainer>
                          <ProductSubText>
                            {product?.productDescription}
                          </ProductSubText>
                        </ProductTextContainer>
                      </ProductIconContainer>
                    </ProductIconTextContainer>
                    <ProductButtonContainer>
                      <ProductButton to={product?.productLink}>
                        Download Now !
                      </ProductButton>
                    </ProductButtonContainer>
                  </ProductRightSection>
                </ProductCard>
              ))}
            </ProductCardContainer>
          </ProductCardMainContainer>
        </ProductsMainContainer>
        {/* New Component */}
      </>
    );
  }
};

export default ProductSection;

import React from "react";
import {
  AchievementsInfoContainer,
  AchievementsMainContainer,
  AchievementsMainHeading,
  AchievementsTextContainer,
  CustomSubText,
  NumberContainer,
  NumberTextContainer,
  SingleBox,
  SubTextContainer,
  TextContainer,
} from "../../../styles/AboutUsStyling/ourachievements/styleOurAchievements";
import { useGetAchievementData } from "../../../customhooks/aboutus/useAboutUs";
import { CircularProgress } from "@mui/material";

const OurAchievements = () => {
  const { Achievement, isLoading } = useGetAchievementData();

  return (
    <>
      <AchievementsMainContainer>
        <AchievementsTextContainer>
          <AchievementsMainHeading>Our Achievements</AchievementsMainHeading>
        </AchievementsTextContainer>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          Achievement.map((achieve) => (
            <React.Fragment key={achieve?.AchievementId}>
              <SubTextContainer>
                <CustomSubText>{achieve?.AchievementDescription}</CustomSubText>
              </SubTextContainer>

              <AchievementsInfoContainer>
                {achieve?.Achievements.map((item, index) => (
                  <SingleBox
                    key={item._id}
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? "rgba(80, 66, 152, 0.12)"
                          : "var(--clr-white)",
                    }}
                  >
                    <NumberTextContainer>
                      <NumberContainer>{item.achievementcount}</NumberContainer>
                      <TextContainer> {item.achievementname}</TextContainer>
                    </NumberTextContainer>
                  </SingleBox>
                ))}
              </AchievementsInfoContainer>
            </React.Fragment>
          ))
        )}
      </AchievementsMainContainer>
    </>
  );
};

export default OurAchievements;

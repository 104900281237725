import React from "react";
import {
  SectionMainContainer,
  TeamMemberMainContainer,
  TeamMemberMainHeading,
  TeamMemberTextContainer,
} from "../../../styles/AboutUsStyling/teammember/styleTeamMember";
import TeamMemberSlider from "../../rightsidecarousel/TeamMemberSlider";

const TeamMember = () => {
  return (
    <>
      <SectionMainContainer>
        <TeamMemberMainContainer>
          <TeamMemberTextContainer>
            <TeamMemberMainHeading>
              Get To Know About Our Team
            </TeamMemberMainHeading>
          </TeamMemberTextContainer>
          <TeamMemberSlider />
        </TeamMemberMainContainer>
      </SectionMainContainer>
    </>
  );
};

export default TeamMember;

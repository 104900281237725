import React from "react";
import Navbar from "../../components/GlobalComponents/appbar/Navbar";

const SteamMIndsVRLabs = () => {
  return (
    <div>
      <Navbar />
      <center>
        {" "}
        <h4
          style={{
            fontFamily: "Poppins",
            fontSize: "50px",
            lineHeight: "60px",
            marginTop: "40px",
          }}
        >
          VR LABS IS COMING SOON
        </h4>
        <p
          style={{
            fontFamily: "Poppins",
            fontSize: "20px",
            lineHeight: "24px",
            marginTop: "20px",
          }}
        >
          Our Team is Working On It....
        </p>
      </center>
    </div>
  );
};

export default SteamMIndsVRLabs;

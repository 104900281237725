import React from "react";
import {
  // CourseButton,
  CourseButtonContainer,
  CourseCard,
  CourseCardContainer,
  CourseContainer,
  CourseName,
  CourseSubText,
  CustomImage,
  IconContainer,
  IconTextContainer,
  ImageContainer,
  LeftSection,
  RightSection,
  TextContainer,
} from "../../../styles/HomeStyling/coursesection/courseSectionStyle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useMediaQuery } from "react-responsive";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetCategory } from "../../../customhooks/homeCustomHooks/category/useCategory";
import MobileCourseCategory from "./mobilecoursecategory/MobileCourseCategory";
const CourseSection = () => {
  const borderColors = ["#EA614E", "#08C5D1", "#FC9A07", "#F4CF0C", "#504298"];
  const isMobile = useMediaQuery({ maxWidth: 560 });
  const { Category, isLoading, isSuccess } = useGetCategory();
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (isSuccess) {
    return (
      <>
        <CourseContainer>
          <CourseCardContainer>
            {Category.map((category, index) => (
              <CourseCard
                key={index}
                style={{
                  flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                  border: `0.5px solid ${borderColors[index]}`,
                }}
              >
                <LeftSection>
                  <ImageContainer>
                    <CustomImage
                      src={category?.categoryImage}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </ImageContainer>
                </LeftSection>
                <RightSection>
                  <CourseName
                    style={{
                      textAlign: index % 2 === 0 ? "start" : "end",
                    }}
                  >
                    {category?.categoryName}
                  </CourseName>
                  {/* </div> */}
                  {category?.categoryDescription.map((description, index) => (
                    <IconTextContainer
                      key={index}
                      // style={{
                      //   flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                      //   textAlign: index % 2 === 0 ? "start" : "end",
                      // }}
                    >
                      <IconContainer
                      // style={
                      //   index % 2 === 0
                      //     ? { marginRight: "20px" }
                      //     : { marginLeft: "35px" }
                      // }
                      >
                        <CheckCircleIcon
                          style={{ color: "var(--clr-secondary)" }}
                        />
                      </IconContainer>
                      <TextContainer>
                        <CourseSubText>{description}</CourseSubText>
                      </TextContainer>
                    </IconTextContainer>
                  ))}
                  <CourseButtonContainer
                    style={{
                      flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                      justifyContent: index % 2 === 0 ? "flex-end" : "flex-end",
                      marginLeft: index % 2 !== 0 ? "10px" : "0px",
                      marginRight: index % 2 === 0 ? "4px" : "0px",
                    }}
                  >
                    {/* <CourseButton
                      style={{
                        marginLeft: index % 2 === 0 ? "55px" : "0px",
                      }}
                    >
                      Enroll Now
                    </CourseButton> */}
                  </CourseButtonContainer>
                </RightSection>
              </CourseCard>
            ))}
          </CourseCardContainer>
          {isMobile && <MobileCourseCategory />}
        </CourseContainer>
      </>
    );
  }
};

export default CourseSection;

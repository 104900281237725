import React from "react";
import {
  ContactMainContainer,
  ImageContainer,
  ContactMainHeroSection,
  RelativeFormContainer,
} from "../../../styles/ContactStyling/contactherosection/styleContactHero";
import ContactForm from "../formsection/ContactForm";

const ContactHeroSection = () => {
  return (
    <>
      <ContactMainContainer>
        <ContactMainHeroSection>
          <ImageContainer>
            {/* <img
              src={ContactBackground}
              alt="Contact"
              // width="100%"
              style={{ width: "100%", height: "100%" }}
            /> */}
          </ImageContainer>
        </ContactMainHeroSection>
        <RelativeFormContainer>
          <ContactForm />
        </RelativeFormContainer>
      </ContactMainContainer>
    </>
  );
};

export default ContactHeroSection;

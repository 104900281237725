import React from "react";
import {
  CustomSubText,
  LeftImage,
  LeftSection,
  MissionVisionDownContainer,
  MissionVissionMainContainer,
  MissionVissionMainHeading,
  MissionVissionSubDescription,
  MissionVissionTextContainer,
  RightMainText,
  RightSection,
  RightSectionTextContainer,
  SubTextContainer,
} from "../../../styles/AboutUsStyling/missionandvision/StyleMissionVision";
import { useGetMissionVissionData } from "../../../customhooks/aboutus/useAboutUs";
import CircularProgress from "@mui/material/CircularProgress";
const MissionVission = () => {
  const { MissionVission, isLoading } = useGetMissionVissionData();
  return (
    <>
      <MissionVissionMainContainer>
        <MissionVissionTextContainer>
          <MissionVissionMainHeading>
            Know The STEAM Minds
          </MissionVissionMainHeading>
          <SubTextContainer>
            <CustomSubText>
              Mission, Vision, Team and Achievemnents{" "}
            </CustomSubText>
          </SubTextContainer>
        </MissionVissionTextContainer>
        {isLoading ? (
          <CircularProgress />
        ) : (
          MissionVission.map((item, index) => (
            <MissionVisionDownContainer
              key={item.InfoId}
              style={{
                flexDirection: index % 2 === 0 ? "row" : "row-reverse",
              }}
            >
              <LeftSection>
                <LeftImage src={item?.InfoImage} />
              </LeftSection>
              <RightSection>
                <RightSectionTextContainer>
                  <RightMainText>{item?.InfoTitle}</RightMainText>
                  <MissionVissionSubDescription>
                    {item?.InfoDescription}
                  </MissionVissionSubDescription>
                </RightSectionTextContainer>
              </RightSection>
            </MissionVisionDownContainer>
          ))
        )}
      </MissionVissionMainContainer>
    </>
  );
};

export default MissionVission;

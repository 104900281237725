import { styled } from "@mui/system";
import { Box, Button, Typography, Card } from "@mui/material";

export const CourseContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  margin: "20px 60px",
  "@media (max-width: 912px)": {
    margin: "50px",
  },
  "@media (max-width: 540px)": {
    margin: "30px",
  },
}));

export const CourseCardContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  "@media (max-width: 600px)": {
    display: "none",
  },
}));

export const CourseCard = styled(Card)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  background: "#FFFFFF",

  boxShadow: "0px 4px 8px 1px rgba(69, 64, 225, 0.25)",
  borderRadius: "14px",
  padding: "14px",
  width: "100%",
  marginBottom: "40px",
  "@media (max-width: 912px)": {
    flexDirection: "column !important",
  },
}));

export const LeftSection = styled(Box)(() => ({
  display: "flex",
}));

export const ImageContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "270px",
  height: "270px",

  borderRadius: "50%",

  overflow: "hidden",
  "@media (max-width: 540px)": {
    marginLeft: "0px",
  },
}));

export const CustomImage = styled("img")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

export const RightSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "50px 70px",
  width: "100%",
}));

export const CourseName = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "45px",
  lineHeight: "28px",
  marginBottom: "20px",
}));

export const IconTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "7px",
  width: "100%",
}));
export const IconContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "7px",
}));

export const TextContainer = styled(Box)(() => ({
  marginTop: "8px",
}));

export const CourseSubText = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  width: "100%",
  fontSize: "17px",
  marginLeft: "12px",
  color: "var(--clr-gray)",

  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export const CourseButtonContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "20px",
}));

export const CourseButton = styled(Button)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "var(--clr-secondary)",
  color: "var(--clr-white)",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: 300,
  lineHeight: "22px",
  textTransform: "capitalize",
  borderRadius: "37px",
  padding: "8px 16px",
  width: "222px",
}));

export const ExploreButtonContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "20px 0px",
}));

export const ExploreButton = styled(Button)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "294px",
  height: "55px",
  padding: "0px, 48px, 0px, 48px",
  background: "var(--clr-red)",
  color: "var(--clr-white)",
  fontFamily: "Poppins",
  borderRadius: "32px",
  textTransform: "capitalize",
  "&:hover": {
    color: "var(--clr-red)",
    border: "0.8px solid var(--clr-red)",
    background: "var(--clr-white)",
    fontWeight: "bold",
  },
  "@media (max-width: 540px)": {
    height: "45px",
  },
  "@media (max-width: 500px)": {
    height: "40px",
  },
}));

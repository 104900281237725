import React from "react";
import {
  AboutUsHeroMainContainer,
  AboutUsImageContainer,
  AboutUsLeftSide,
  AboutUsLeftSideContainer,
  AboutUsLeftSideMainHeding,
  AboutUsLeftSideSubHeding,
} from "../../../styles/AboutUsStyling/aboutusherosection/styleAboutUsHeroSection";
import { useGetAboutUs } from "../../../customhooks/aboutus/useAboutUs";
import { CircularProgress } from "@mui/material";
const AboutUsHeroSection = () => {
  const { TopBannerData, isLoading, isSuccess } = useGetAboutUs();
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (isSuccess) {
    return (
      <>
        <AboutUsHeroMainContainer>
          {TopBannerData.map((data) => (
            <React.Fragment key={data?.TopBannerId}>
              <AboutUsLeftSide>
                <AboutUsLeftSideContainer>
                  <AboutUsLeftSideMainHeding>
                    About Us
                  </AboutUsLeftSideMainHeding>
                  <AboutUsLeftSideSubHeding>
                    {data?.TopBannerDescription}
                  </AboutUsLeftSideSubHeding>
                </AboutUsLeftSideContainer>
              </AboutUsLeftSide>
              <AboutUsImageContainer>
                <img
                  // src={AboutusImg}
                  src={data?.TopBannerImage}
                  alt="steam"
                  style={{
                    height: "370px",
                    objectFit: "contain",
                    width: "100%",
                  }}
                />
              </AboutUsImageContainer>
            </React.Fragment>
          ))}
        </AboutUsHeroMainContainer>
      </>
    );
  }
};

export default AboutUsHeroSection;

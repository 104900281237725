import React from "react";
import {
  AddressItem,
  BottomText,
  FooterMainContainer,
  FooterRightContainer,
  FooterTopHeading,
  FooterTopHeadingContainer,
  LogoSection,
  ProductCompanyContainer,
  SocialMediaIconContainer,
  SupportContactContainer,
  TopHeadingItems,
  LogoLinkMainContainer,
  BottomSection,
  CustomFooterDivider,
  AllRightReseverdContainer,
  AllRightReseverd,
} from "../../../styles/GlobalsStyling/footer/styleFooter";
// import { LogoContainer } from "../../styles/pagesStyle/signup/signupStyle";
import { LogoContainer } from "../../../styles/AuthenticationStyling/signup/signupStyle";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { FooterLinks } from "../../../dummydata/DummyData";
import { useFooter } from "../../../customhooks/Navbar/Footer/useFooter";

const Footer = () => {
  const { handleFacebook, handleinstagram, handlelinkedin, handletwitter } =
    useFooter();
  const handleScroll = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  const handleWordsProduct = () => {
    window.scrollTo(0, 700);
  };
  // const handleDrawingProduct = () => {
  //   window.scrollTo(0, 1250);
  // };
  // const handleMadsProduct = () => {
  //   window.scrollTo(0, 1900);
  // };
  // const handleKitProduct = () => {
  //   window.scrollTo(0, 2450);
  // };

  return (
    <>
      <FooterMainContainer>
        <LogoLinkMainContainer>
          <LogoSection>
            <LogoContainer></LogoContainer>
            <BottomText>Connect With Us</BottomText>
            <SocialMediaIconContainer>
              <FacebookOutlinedIcon
                sx={{ width: "18px", marginRight: "5px", cursor: "pointer" }}
                onClick={handleFacebook}
              />
              <TwitterIcon
                sx={{ width: "18px", marginRight: "5px", cursor: "pointer" }}
                onClick={handletwitter}
              />
              <InstagramIcon
                sx={{ width: "18px", marginRight: "5px", cursor: "pointer" }}
                onClick={handleinstagram}
              />
              <LinkedInIcon
                sx={{ width: "18px", cursor: "pointer" }}
                onClick={handlelinkedin}
              />
            </SocialMediaIconContainer>
          </LogoSection>
          <FooterRightContainer>
            <ProductCompanyContainer>
              <FooterTopHeadingContainer>
                <FooterTopHeading>Product</FooterTopHeading>
                <TopHeadingItems
                  to="/steam-products"
                  onClick={handleWordsProduct}
                >
                  STEAM Words
                </TopHeadingItems>
                <TopHeadingItems to="http://vrlab.steamminds.org/">
                  VR Labs
                </TopHeadingItems>
                {/* <TopHeadingItems
                  to="/steam-products"
                  onClick={handleDrawingProduct}
                >
                  Kids Drawing
                </TopHeadingItems> */}
                {/* <TopHeadingItems
                  to="/steam-products"
                  onClick={handleMadsProduct}
                >
                  Mads Mind
                </TopHeadingItems>
               
                <TopHeadingItems
                  to="/steam-products"
                  onClick={handleKitProduct}
                >
                  ABC Learning Game
                </TopHeadingItems> */}
              </FooterTopHeadingContainer>
              <FooterTopHeadingContainer>
                <FooterTopHeading>Company</FooterTopHeading>
                {FooterLinks.map((footerlink) => (
                  <TopHeadingItems
                    to={footerlink?.path}
                    onClick={handleScroll}
                    key={footerlink.id}
                  >
                    {footerlink?.name}
                  </TopHeadingItems>
                ))}
              </FooterTopHeadingContainer>
            </ProductCompanyContainer>
            <SupportContactContainer>
              {/* <FooterTopHeadingContainer>
                <FooterTopHeading>Support</FooterTopHeading>
                <TopHeadingItems>FAQ</TopHeadingItems>
                <TopHeadingItems>Help Center</TopHeadingItems>
                <TopHeadingItems>Feedback</TopHeadingItems>
                <TopHeadingItems>Report a bug</TopHeadingItems>
                <TopHeadingItems>Chat Support</TopHeadingItems>
              </FooterTopHeadingContainer> */}
              <FooterTopHeadingContainer>
                <FooterTopHeading>Contact Us</FooterTopHeading>
                <TopHeadingItems>info@steamminds.org</TopHeadingItems>
                <TopHeadingItems>+92 334 2986462</TopHeadingItems>
                <AddressItem>
                  1st Floor IT Park near Missile Chowk, Mandian, Abbottabad,
                  Pakistan
                </AddressItem>
              </FooterTopHeadingContainer>
            </SupportContactContainer>
          </FooterRightContainer>
        </LogoLinkMainContainer>
        <BottomSection>
          {/* <Divider
            sx={{ backgroundColor: "white", height: "24px", width: "100%" }}
          /> */}
          <CustomFooterDivider />
          <AllRightReseverdContainer>
            <AllRightReseverd>
              Copyright &copy; 2023 STEAM Minds
            </AllRightReseverd>
            <AllRightReseverd>
              All Rights Reserved | Terms and Conditions | Privacy Policy
            </AllRightReseverd>
          </AllRightReseverdContainer>
        </BottomSection>
      </FooterMainContainer>
    </>
  );
};

export default Footer;

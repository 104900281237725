import { styled } from "@mui/material/styles";

export const ErrorMessage = styled("span")(() => ({
  fontFamily: "Poppins",
  fontWeight: 300,
  fontSize: "12px",
  paddingTop: "4px",
  //   marginTop: "20px",
  color: "var(--clr-red)",
}));

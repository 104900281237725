import React from "react";
import Navbar from "../../components/GlobalComponents/appbar/Navbar";
import HeroSection from "../../components/HomeComponents/herosection/HeroSection";
import ExploreCategory from "../../components/HomeComponents/explorecategroy/ExploreCategory";
import CourseSection from "../../components/HomeComponents/coursesection/CourseSection";
import OurProgram from "../../components/HomeComponents/ourprograms/OurPrograms";
import VideoSection from "../../components/HomeComponents/powerofsteamminds/VideoSection";
import JoinLearningCommunity from "../../components/HomeComponents/learningcommunity/JoinLearningCommunity";
import StudentReviews from "../../components/HomeComponents/studentreviews/StudentReviews";
import AskedQuestions from "../../components/HomeComponents/askedquestions/AskedQuestions";
import Footer from "../../components/GlobalComponents/footer/Footer";

import {
  ExploreButton,
  ExploreButtonContainer,
} from "../../styles/HomeStyling/coursesection/courseSectionStyle";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  const handleExploreCourseButton = () => {
    navigate("/explore-allcourses");
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Navbar />
      <div>
        <HeroSection />
        <ExploreCategory />
        <div>
          <CourseSection />
          <ExploreButtonContainer>
            <ExploreButton onClick={handleExploreCourseButton}>
              Explore All Courses
            </ExploreButton>
          </ExploreButtonContainer>
        </div>
        <OurProgram />
        <VideoSection />
        <JoinLearningCommunity />
        <StudentReviews />
        <AskedQuestions />
        <Footer />
      </div>

      {/* <CourseCategory /> */}
    </>
  );
};

export default Home;

import { Card } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/system";

export const VideoLectureMainContainer = styled(Box)(() => ({
  padding: "30px",
  margin: "10px",
}));
export const VideoLectureContainer = styled(Box)(() => ({
  display: "grid",
  marginTop: "35px",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "20px",
  "@media (max-width: 912px)": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media (max-width: 480px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));

export const CustomVideoCard = styled(Card)(() => ({
  width: "100%",
  height: "340px",
  cursor: "pointer",
  borderRadius: "20px",
  paddingBottom: "20px",
}));

export const CustomVideoCardImage = styled(Box)(() => ({
  width: "100%",
  height: "220px",

  "@media (max-width: 540px)": {
    height: "250px",
  },
}));
export const DescriptionContainer = styled(Box)(() => ({
  padding: "20px 15px",
}));

export const CourseVideoCardHeading = styled(Box)(() => ({
  color: "black",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: 700,
  lineHeight: "20px",
  letterSpacing: "1.02",
}));

export const VideoDescriptionContainer = styled(Box)(() => ({
  marginTop: "8px",
}));

export const VideoCourseCardDescription = styled(Box)(() => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  textAlign: "justify",
  width: "100%",
  fontSize: "13px",
  color: "var(--clr-gray)",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

import { Box } from "@mui/system";
import { styled } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@mui/material";
export const CoursePaymentLeftSide = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  background: "var(--clr-white)",
  padding: "0px 0px 0px 0px",
}));

export const CoursePaymentHeading = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "24px",
  fontWeight: 300,
  color: "var(--clr-main-heading)",
}));

export const CoursePaymentDescription = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  fontFamily: "Inter",
  fontSize: "15px",
  fontWeight: 600,
  marginTop: "8px",
  color: "var(--clr-small-main-heading)",
}));

export const PaymentInformationSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "0px",
}));
export const CardSection = styled(Box)(() => ({
  display: "flex",
  marginTop: "20px",
  alignItems: "center",
  flexDirection: "row",
  width: "100%",
}));

export const CardName = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const SavedCards = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  fontFamily: "Inter",
  fontSize: "18px",
  fontWeight: 600,
  marginTop: "15px",
  color: "var(--clr-small-main-heading)",
}));

export const SavedCardsSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  marginTop: "24px",
}));
export const CardsSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  width: "50%",
  padding: "40px 0px",
  background: "red",
}));
export const AddingCardSection = styled(Box)(() => ({
  flexDirection: "row",
  width: "100%",
}));
export const StyledAccordion = styled(Accordion)(() => ({
  backgroundColor: "var(--clr-white)",
  // margin: "10px 0px",
  width: "100%",
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  backgroundColor: "var(--clr-white)",
  color: "var(--clr-black)",
  marginTop: "10px",
  fontSize: "20px",
  fontFamily: "Inter",
  padding: "0px 12px",
  lineHeight: "20px",
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  backgroundColor: "var(--clr-white)",
  textAlign: "justify",
  height: "300px",
}));
export const PaymentCustomTextField = styled("input")(() => ({
  backgroundColor: "var(--clr-white)",
  color: "var(--clr-black)",
  fontSize: "14px",
  fontFamily: "Inter",
  width: "100%",
  padding: "12px",
  border: `1px solid var(--clr-ash)`,
  lineHeight: "15px",
  marginBottom: "20px",
  borderRadius: "5px",
}));

export const CardCvvExpiryContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  // backgroundColor: "var(--clr-white)",
  // color: "var(--clr-black)",
  // fontSize: "14px",
  // fontFamily: "Inter",
  // width: "100%",
  // padding: "12px",
  // border: "1px solid #F5F5F5",
  // lineHeight: "15px",
  // marginBottom: "20px",
}));
export const ExpiryCvvInput = styled("input")(() => ({
  backgroundColor: "var(--clr-white)",
  color: "var(--clr-black)",
  fontSize: "14px",
  fontFamily: "Inter",
  width: "45%",
  padding: "12px",
  border: `1px solid var(--clr-ash)`,
  lineHeight: "15px",
  marginBottom: "20px",
  borderRadius: "5px",
}));
export const AddingCardButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid var(--clr-ash)`,
  color: "var(--clr-secondary)",
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: 700,
  padding: "12px",
  borderRadius: "5px",
  cursor: "pointer",
  "&:hover": {
    background: "var(--clr-secondary)",
    color: "var(--clr-white)",
  },
}));
export const DifferentPaymentCardContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "flex-start",
  background: "var(--clr-white)",
  padding: "0px 0px 0px 0px",
}));

export const CustomFormControl = styled(FormControl)(() => ({
  marginBottom: "15px",
}));

export const CustomFormLabel = styled(FormLabel)(() => ({
  marginBottom: "12px",
}));

export const CustomRadioGroup = styled(RadioGroup)(() => ({
  flexDirection: "column",
}));

export const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  color: "red",
}));

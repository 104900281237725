import { useMemo } from "react";
import { useGetFaqQuery } from "../../features/faqQuestions/FaqSlice";

export const useGetFaq = () => {
  const { data, isSuccess, isLoading } = useGetFaqQuery();

  const FAQInfo = useMemo(() => {
    const getFAQ = data || [];
    return getFAQ?.map((faq) => ({
      questionId: faq?.questionId,
      question: faq?.question,
      description: faq?.description,
    }));
  }, [data]);
  return {
    FAQ: FAQInfo,
    isLoading,
    isSuccess,
  };
};

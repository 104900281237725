import React, { useState } from "react";
import {
  CoursePaymentLeftSide,
  AddingCardButton,
  AddingCardSection,
  CardCvvExpiryContainer,
  ExpiryCvvInput,
  PaymentCustomTextField,
  PaymentInformationSection,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  CustomFormControl,
  CustomRadioGroup,
  CustomFormControlLabel,
  CoursePaymentHeading,
  CoursePaymentDescription,
} from "../../../../styles/ExploreCourseStyling/proceedtopay/addcardinfo/styleAddCardInfo";
import { Radio } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const AddingCardInfo = () => {
  const [value, setValue] = useState("female");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <CoursePaymentLeftSide>
      <CoursePaymentHeading>Payment</CoursePaymentHeading>
      <CoursePaymentDescription>
        Your Order is 1 Step away
      </CoursePaymentDescription>

      <PaymentInformationSection>
        <CustomFormControl component="fieldset">
          <CustomRadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <CustomFormControlLabel
              value="saved card"
              control={<Radio />}
              label="Credit Card / Debit Card"
            />
          </CustomRadioGroup>
          <AddingCardSection>
            <StyledAccordion>
              <StyledAccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{
                      background: "var(--clr-secondary)",
                      borderRadius: "50px",
                      color: "var(--clr-white)",
                    }}
                  />
                }
              >
                Add Your Card Information
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <>
                  <PaymentCustomTextField placeholder="Name on Card" />
                  <PaymentCustomTextField placeholder="1234 4567 7890 1234" />
                  <CardCvvExpiryContainer>
                    <ExpiryCvvInput placeholder="Name on Card" />
                    <ExpiryCvvInput placeholder="1234 4567 7890 1234" />
                  </CardCvvExpiryContainer>
                  <AddingCardButton>Place the order</AddingCardButton>
                </>
              </StyledAccordionDetails>
            </StyledAccordion>
          </AddingCardSection>
        </CustomFormControl>
      </PaymentInformationSection>
    </CoursePaymentLeftSide>
  );
};

export default AddingCardInfo;

import { Box } from "@mui/system";
import { styled } from "@mui/system";

export const AmbassadorsMainContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 60px",
  width: "100%",
  background: "var(--clr-secondary)",
  "@media (max-width: 820px)": {
    flexDirection: "column",
    padding: "20px 40px",
  },
}));

export const AmbassadorsLeftSide = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

export const AmbassadorsLeftSideContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
}));

export const AmbassadorsLeftSideMainHeding = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "50px",
  fontWeight: 700,
  lineHeight: "66px",
  padding: "35px 0px 0px 0px",
  color: "var(--clr-white)",
  "@media (max-width: 912px)": {
    fontSize: "45px",
    lineHeight: "55px",
  },
  "@media (max-width: 820px)": {
    fontSize: "40px",
    lineHeight: "50px",
  },
  "@media (max-width: 540px)": {
    fontSize: "35px",
    lineHeight: "35px",
  },
  "@media (max-width: 400px)": {
    fontSize: "30px",
    lineHeight: "40px",
  },
}));

export const AmbassadorsLeftSideSubHeding = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "17px",
  fontWeight: 300,
  // textAlign: "justify",
  lineHeight: "30px",
  letterSpacing: "0.4px",
  padding: "15px 0px 0px 0px",
  color: "var(--clr-white)",
  "@media (max-width: 912px)": {
    lineHeight: "28px",
    letterSpacing: "0.2px",
  },
}));

export const AmbassadorsImageContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // margin: "8px",
  // padding: "4px",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  "@media (max-width: 912px)": {
    width: "100%",
    margin: "20px",
  },
  "@media (max-width: 540px)": {
    width: "90%",
    margin: "20px",
  },
}));

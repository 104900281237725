import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
// import { FreeMode, Pagination, Navigation } from "swiper/core";
// import required modules
import { FreeMode, Pagination, Navigation } from "swiper";
import { Divider } from "@mui/material";
import {
  CourseCarouselContainer,
  CourseSliderContainer,
  CourseSliderTopSection,
  // CustomLeftImage,
  NameCategoryContainer,
  StudentName,
  CategoryName,
  StudentReviewDescription,
  CustomLeftImageContainer,
} from "../../../../styles/ExploreCourseStyling/singlecoursetestimonials/styleCourseTestimonials";
import { useParams } from "react-router-dom";
import { useGetCourseTestimonialsByIdQuery } from "../../../../features/courses/coursetestimonials/courseTestimonialSlice";
const SingleCourseTestimonials = () => {
  const { id } = useParams();
  const { data: testimonialsData = [] } = useGetCourseTestimonialsByIdQuery(id);

  const breakpoints = {
    1500: {
      slidesPerView: 2,
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 15,
    },
    912: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 15,
    },

    540: {
      slidesPerView: 1,
    },
  };
  return (
    <>
      <CourseCarouselContainer>
        <Swiper
          breakpoints={breakpoints}
          spaceBetween={35}
          freeMode={true}
          rewind={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[FreeMode, Pagination, Navigation]}
          className="mySwiper"
        >
          {testimonialsData.map((student) => (
            <SwiperSlide key={student?._id}>
              <CourseSliderContainer>
                <CourseSliderTopSection>
                  <CustomLeftImageContainer>
                    <img
                      src={student?.userpic}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50px",
                        objectFit: "cover",
                      }}
                      alt="steam student"
                    />
                  </CustomLeftImageContainer>
                  <NameCategoryContainer>
                    <StudentName>{student?.name}</StudentName>
                    <CategoryName>{student?.coursename}</CategoryName>
                  </NameCategoryContainer>
                </CourseSliderTopSection>
                <Divider />

                <StudentReviewDescription>
                  {student?.content}
                </StudentReviewDescription>
              </CourseSliderContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </CourseCarouselContainer>
    </>
  );
};

export default SingleCourseTestimonials;

import React from "react";
import {
  CourseHeroRightImage,
  CourseImageContainer,
  CourseLeftSide,
  CourseLeftSideBottomHeding,
  CourseLeftSideContainer,
  CourseLeftSideMainHeding,
  CourseLeftSideSubHeding,
  CourseMainContainer,
} from "../../../styles/ExploreCourseStyling/exploreCourseHero/exploreCourseStyling";
import CurveImg from "../../../assets/images/allcourses.png";
const CourseHeroSection = () => {
  return (
    <>
      <CourseMainContainer>
        <CourseLeftSide>
          <CourseLeftSideContainer>
            <CourseLeftSideMainHeding>
              Explore All Our Courses
            </CourseLeftSideMainHeding>
            <CourseLeftSideSubHeding>
              Expand Your Knowledge, Explore Our Diverse Course Collection
            </CourseLeftSideSubHeding>
            <CourseLeftSideBottomHeding>
              Find Your Perfect Course
            </CourseLeftSideBottomHeding>
          </CourseLeftSideContainer>
        </CourseLeftSide>
        <CourseImageContainer>
          {/* <img
            src={CurveImg}
            alt="CourseImage"
            style={{ height: "370px", objectFit: "cover" }}
          /> */}
          <CourseHeroRightImage src={CurveImg} alt="CourseImage" />
        </CourseImageContainer>
      </CourseMainContainer>
    </>
  );
};

export default CourseHeroSection;

import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

export const StudentCarouselContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "30px 0px",
  width: "100%",
  height: "100%",
}));
export const SliderContainer = styled(Box)(() => ({
  borderRadius: "18px",
  backgroundColor: "var(--clr-white)",
  margin: "30px 20px",
  border: "1px solid #EFF0F6",
  boxShadow: "0px 5px 14px 0px rgba(8, 15, 52, 0.04)",
}));
export const SliderTopSection = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "0px",
  padding: "12px",
}));

export const LeftImageContainer = styled(Box)(() => ({
  width: "80px",
  height: "80px",
}));
export const CustomLeftImage = styled("img")(() => ({
  width: "75px",
  height: "75px",
  borderRadius: "50%",
  objectFit: "cover",
}));
export const NameCategoryContainer = styled(Box)(() => ({
  alignItems: "end",
  textAlign: "end",
}));
export const StudentName = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontWeight: 700,
  fontSize: "22px",
  color: "#444",
}));
export const CategoryName = styled(Typography)(() => ({
  fontSize: "12px",
  fontFamily: "Poppins",
  fontWeight: 300,

  color: "var(--clr-black)",
}));

export const StudentReviewDescription = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontSize: "14px",

  color: "var(--clr-card-text)",
  padding: "5px 12px",
  margin: "22px 2px",
  textAlign: "justify",
}));

import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

export const AboutUsHeroMainContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 60px",
  width: "100%",
  background: "var(--clr-secondary)",
  "@media (max-width: 820px)": {
    flexDirection: "column",
    padding: "20px 40px",
  },
  "@media (max-width: 400px)": {
    padding: "20px 30px",
  },
}));

export const AboutUsLeftSide = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  "@media (max-width: 820px)": {
    width: "100%",
  },
}));

export const AboutUsLeftSideContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
}));

export const AboutUsLeftSideMainHeding = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "50px",
  fontWeight: 700,
  lineHeight: "66px",
  padding: "35px 0px 0px 0px",
  color: "var(--clr-white)",
  "@media (max-width: 912px)": {
    fontSize: "45px",
    lineHeight: "55px",
  },
  "@media (max-width: 820px)": {
    fontSize: "40px",
    lineHeight: "50px",
  },
  "@media (max-width: 540px)": {
    fontSize: "35px",
    lineHeight: "28px",
  },
}));

export const AboutUsLeftSideSubHeding = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "17px",
  fontWeight: 300,
  lineHeight: "30px",
  letterSpacing: "0.4px",
  padding: "15px 0px 0px 0px",
  color: "var(--clr-white)",
  "@media (max-width: 912px)": {
    lineHeight: "28px",
    letterSpacing: "0.2px",
  },
}));

export const AboutUsImageContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  "@media (max-width: 912px)": {
    width: "100%",
    margin: "20px",
  },
  "@media (max-width: 540px)": {
    width: "100%",
  },
}));

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { authApi } from "../features/authentication/authApiSlice";
import { FaqApi } from "../features/faqQuestions/FaqSlice";
import { ContactApi } from "../features/contactus/contactSlice";
import { CourseApi } from "../features/courses/courseSlice";
import { CourseLessonApi } from "../features/coursesLessons/courseLessonSlice";
import authReducer from "../features/authentication/authSlice";
import { CourseFaqApi } from "../features/courses/coursequestion/coureFaqSlice";
import { CourseTestimonialApi } from "../features/courses/coursetestimonials/courseTestimonialSlice";
import { AnnouncementApi } from "../features/home/homesecondbanner/announcementSlice";
import { CategoryApi } from "../features/home/category/categorySlice";
import { CourseLessonVideoApi } from "../features/coursesLessons/courselessonvideos/courseLessonVideoSlice";
import { HomeTestimonialApi } from "../features/home/hometestimonials/homeTestimonialsSlice";
import { AboutUsApi } from "../features/aboutus/topbanner/aboutusSlice";
import { MissionVissionApi } from "../features/aboutus/missionvission/missionvVissionSlice";
import { TeamMemberApi } from "../features/aboutus/teammember/teamMemberSlice";
import { CompetationApi } from "../features/aboutus/competation/competationSlice";
import { AchievementsApi } from "../features/aboutus/achievements/achivementsSlice";
import { AmbassadorApi } from "../features/ambassadors/topbardata/ambassadorSlice";
import { AmbassadorInfoApi } from "../features/ambassadors/ambassadorinfo/ambassadorInfoSlice";
import { ProductTopBannerApi } from "../features/ourproducts/topbanner/ProductTopBannerSlice";
import { ProductsApi } from "../features/ourproducts/products/productSlice";
import { CourseModuleApi } from "../features/courses/coursemodule/courseModuleSlice";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [FaqApi.reducerPath]: FaqApi.reducer,
    [HomeTestimonialApi.reducerPath]: HomeTestimonialApi.reducer,
    [ContactApi.reducerPath]: ContactApi.reducer,
    [CourseApi.reducerPath]: CourseApi.reducer,
    [CourseModuleApi.reducerPath]: CourseModuleApi.reducer,
    [CourseLessonApi.reducerPath]: CourseLessonApi.reducer,
    [CourseLessonVideoApi.reducerPath]: CourseLessonVideoApi.reducer,
    [CourseFaqApi.reducerPath]: CourseFaqApi.reducer,
    [CourseTestimonialApi.reducerPath]: CourseTestimonialApi.reducer,
    [AnnouncementApi.reducerPath]: AnnouncementApi.reducer,
    [CategoryApi.reducerPath]: CategoryApi.reducer,
    [AboutUsApi.reducerPath]: AboutUsApi.reducer,
    [MissionVissionApi.reducerPath]: MissionVissionApi.reducer,
    [TeamMemberApi.reducerPath]: TeamMemberApi.reducer,
    [CompetationApi.reducerPath]: CompetationApi.reducer,
    [AchievementsApi.reducerPath]: AchievementsApi.reducer,
    [AmbassadorApi.reducerPath]: AmbassadorApi.reducer,
    [AmbassadorInfoApi.reducerPath]: AmbassadorInfoApi.reducer,
    [ProductTopBannerApi.reducerPath]: ProductTopBannerApi.reducer,
    [ProductsApi.reducerPath]: ProductsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      FaqApi.middleware,
      HomeTestimonialApi.middleware,
      ContactApi.middleware,
      CourseApi.middleware,
      CourseModuleApi.middleware,
      CourseLessonVideoApi.middleware,
      CourseLessonApi.middleware,
      CourseFaqApi.middleware,
      CourseTestimonialApi.middleware,
      AnnouncementApi.middleware,
      CategoryApi.middleware,
      AboutUsApi.middleware,
      MissionVissionApi.middleware,
      TeamMemberApi.middleware,
      CompetationApi.middleware,
      AchievementsApi.middleware,
      AmbassadorApi.middleware,
      AmbassadorInfoApi.middleware,
      ProductTopBannerApi.middleware,
      ProductsApi.middleware
    ),
});
setupListeners(store.dispatch);

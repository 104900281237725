import { useMemo } from "react";
import { useGetCourseQuery } from "../../features/courses/courseSlice";

export const useGetCourses = () => {
  const { data, isSuccess, isLoading } = useGetCourseQuery();
  const CourseInfo = useMemo(() => {
    const getCourse = data || [];

    return getCourse?.map((course) => ({
      courseId: course?._id,
      coursename: course?.name,
      homepointdescription: course?.homepointdescription,
      homecourse: course?.homecourse,
      level: course?.level,
      courseModule: course?.lesson,
      courseCategory: course?.category,
    }));
  }, [data]);
  return {
    Course: CourseInfo,
    isLoading,
    isSuccess,
  };
};

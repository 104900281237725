import ScienceImg from "../assets/images/science.png";
import TechnologyImg from "../assets/images/technology.png";
import EngineeringImg from "../assets/images/enginnering.png";
import ArtsImg from "../assets/images/arts.png";
import MathsImg from "../assets/images/maths.png";

import WebImage from "../../src/assets/images/webplateform.png";
import OnLineClassesImage from "../../src/assets/images/onlineclasses.png";
import GameLearningImage from "../../src/assets/images/gamefiedlearning.png";

import ScienceImage from "../../src/assets/images/scienceimage.png";

import OnlineClasses from "../assets/images/onlineclasses.png";
import Mission from "../assets/images/mission.png";
import Vission from "../assets/images/vission.png";
import MehtakKhalid from "../assets/images/mehtabkhalid.png";
import IbrahimKhalid from "../assets/images/ibrahimkhalid.png";
import AkhtarHussain from "../assets/images/iftikhar.png";
import Nouman from "../assets/images/nouman.png";
import AdvisoryBoard from "../assets/images/advisoryboard.png";

import MobileHomeIcon from "../assets/images/mobilehome.png";

import MobileAboutIcon from "../assets/images/mobileaboutus.png";
import MobileCoursesIcon from "../assets/images/mobilecourses.png";
import MobileAmbassadorIcon from "../assets/images/mobileambassador.png";
import MobileVRLabs from "../assets/images/vrlabs.png";
import MobileProducts from "../assets/images/product.png";
import MobileBlogIcon from "../assets/images/blog.png";
import MobileContactIcon from "../assets/images/mobilecontact.png";
import BlogThumbnail from "../assets/images/blogthumbnail.jpg";
import MusabRehman from "../assets/images/musaeb.png";
import MahnoorFatima from "../assets/images/mahnoorfatima.png";
import KidsDrawing from "../assets/images/kidsdrawing.png";
import WordsFind from "../assets/images/wordsfind.png";
import MadMinds from "../assets/images/madsmind.png";
import Product from "../assets/images/productrightimage.png";
export const Courses = [
  {
    id: 1,
    name: "Science",
    img: ScienceImg,
    Description: [
      {
        id: 1,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 2,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 3,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 4,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
    ],
  },
  {
    id: 2,
    name: "Technology",
    img: TechnologyImg,
    Description: [
      {
        id: 1,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 2,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 3,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 4,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
    ],
  },
  {
    id: 3,
    name: "Engineering",
    img: EngineeringImg,
    Description: [
      {
        id: 1,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 2,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 3,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 4,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
    ],
  },
  {
    id: 4,
    name: "Arts",
    img: ArtsImg,
    Description: [
      {
        id: 1,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 2,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 3,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 4,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
    ],
  },
  {
    id: 5,
    name: "Maths",
    img: MathsImg,
    Description: [
      {
        id: 1,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 2,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 3,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
      {
        id: 4,
        des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit, a sss swe",
      },
    ],
  },
];

export const ProgrammsData = [
  {
    id: 1,
    img: WebImage,
    desc: "Web platforms with immersive and simulative learning",
  },
  {
    id: 2,
    img: OnLineClassesImage,
    desc: "Online classes with personalized sessions",
  },
  {
    id: 3,
    img: GameLearningImage,
    desc: "Gamified and Interactive mobile apps",
  },
];

export const studentData = [
  {
    id: 1,
    name: "Azaan Shareef",
    image: WebImage,
    category: "Scratch Programming",
    description:
      "It was a great experience with the instructor. I enjoyed the coding classes and understood well. I am now capable to get into the field. In this course I have developed stronger concepts of scratch programming. Instructors were available timely and responded very politely. The curriculum covered in specified time. Thank you!",
  },
  {
    id: 2,
    name: "Jane Smith",
    image: OnLineClassesImage,
    category: "Scratch Programming",
    description:
      "Assalam o Alikum, I'm Ayaan. Right now I am enrolled in STEAM Minds' online course 'Scratch Programming'. I am learning how to code in Scratch. I really like the way my instructor teaches me. My instructor is so supportive and always there to help me. I think STEAM Minds is a fun online platform to learn how to code, and I think other kids would like it too!",
  },
  {
    id: 3,
    name: "Jane Smith",
    image: OnLineClassesImage,
    category: "Scratch Programming",
    description:
      "This online Math-A-Genius course by STEAM Minds has helped me develop my math skills in such an interactive and fun way. The team was very cooperative and environment was learning friendly. I highly recommend it!",
  },
  {
    id: 4,
    name: "Jane Smith",
    image: OnLineClassesImage,
    category: "Scratch Programming",
    description:
      "This online Math-A-Genius course by STEAM Minds has helped me develop my math skills in such an interactive and fun way. The team was very cooperative and environment was learning friendly. I highly recommend it!",
  },
  {
    id: 5,
    name: "Jane Smith",
    image: OnLineClassesImage,
    category: "Scratch Programming",
    description:
      "Assalam o Alikum, I'm Ayaan. Right now I am enrolled in STEAM Minds' online course 'Scratch Programming'. I am learning how to code in Scratch. I really like the way my instructor teaches me. My instructor is so supportive and always there to help me. I think STEAM Minds is a fun online platform to learn how to code, and I think other kids would like it too!",
  },
  {
    id: 6,
    name: "Azaan Shareef",
    image: WebImage,
    category: "Scratch Programming",
    description:
      "It was a great experience with the instructor. I enjoyed the coding classes and understood well. I am now capable to get into the field. In this course I have developed stronger concepts of scratch programming. Instructors were available timely and responded very politely. The curriculum covered in specified time. Thank you!",
  },
  {
    id: 7,
    name: "Jane Smith",
    image: OnLineClassesImage,
    category: "Scratch Programming",
    description:
      "Assalam o Alikum, I'm Ayaan. Right now I am enrolled in STEAM Minds' online course 'Scratch Programming'. I am learning how to code in Scratch. I really like the way my instructor teaches me. My instructor is so supportive and always there to help me. I think STEAM Minds is a fun online platform to learn how to code, and I think other kids would like it too!",
  },
];

export const accordionData = [
  {
    id: 1,
    question: "Is STEAM education only for advanced students?",
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sapiente non modi veniam molestiae. Libero dolor inventore impedit officia tempore minima. Est et quo ratione quidem maxime mollitia nemo dicta labore! Est et quo ratione quidem maxime mollitia nemo dicta labore!",
  },
  {
    id: 2,
    question: "What is STEAM education?",
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sapiente non modi veniam molestiae. Libero dolor inventore impedit officia tempore minima. Est et quo ratione quidem maxime mollitia nemo dicta labore! Est et quo ratione quidem maxime mollitia nemo dicta labore!",
  },
  {
    id: 3,
    question: "Why is STEAM education important?",
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sapiente non modi veniam molestiae. Libero dolor inventore impedit officia tempore minima. Est et quo ratione quidem maxime mollitia nemo dicta labore! Est et quo ratione quidem maxime mollitia nemo dicta labore!",
  },
];

export const ExploreCourseData = [
  {
    id: 1,
    image: ScienceImage,
    course: "Science",
    category: "science",
    level: "beginner",
    description: [
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
    ],
  },
  {
    id: 2,
    course: "Art",
    category: "science",
    level: "beginner",
    description: [
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
    ],
  },
  {
    id: 3,
    course: "Mathematics",
    category: "science",
    level: "beginner",
    description: [
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
    ],
  },
  {
    id: 4,
    course: "History",
    category: "science",
    level: "intermediate",
    description: [
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
    ],
  },
  {
    id: 5,
    course: "Geography",
    category: "art",
    level: "intermediate",
    description: [
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
    ],
  },
  {
    id: 6,
    course: "Computer Science",
    category: "art",
    level: "begginer",
    description: [
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
    ],
  },
  {
    id: 7,
    course: "Literature",
    category: "mentalmath",
    level: "intermediate",
    description: [
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
    ],
  },
  {
    id: 8,
    course: "Music",
    category: "mentalmath",
    level: "beginner",
    description: [
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
    ],
  },
  {
    id: 9,
    course: "Sports",
    category: "mentalmath",
    level: "beginner",
    description: [
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
    ],
  },
  {
    id: 10,
    course: "Languages",
    category: "art",
    level: "beginner",
    description: [
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
    ],
  },
  {
    id: 11,
    course: "Physics",
    description: [
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
    ],
  },
  {
    id: 12,
    course: "Chemistry",
    description: [
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
    ],
  },
  {
    id: 13,
    course: "Biology",
    description: [
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
      "Lorem ipsum dolor sit amet consectetur?",
    ],
  },
];

export const Ambassadorsdata = [
  {
    id: 1,
    image: MusabRehman,
    name: "M. Musab Rehman",
    subText: "STEAM Ambassador",
    description:
      "M. Musab Rehan, Grade VII A student, excels in Scratch course, displaying remarkable performance. An STEAM Minds Ambassador at IISAR Foundation, representing our STEAM Minds initiative.",
  },
  {
    id: 2,
    image: MahnoorFatima,
    name: "Mahnoor Fatima",
    subText: "STEAM Ambassador",
    description:
      "Meet Mahnoor Fatima, a standout in Grade VII B. Her exceptional performance in the Scratch course led her to become an STEAM Minds Ambassador at IISAR Foundation, effectively representing our STEAM Minds endeavor.",
  },
];

export const missionVisionData = [
  {
    id: 1,
    mainHeading: "Our Mission",
    imageUrl: Mission,
    subDescription:
      "Our mission is to offer innovative STEAM education to K12 students through personalized, interactive, and gamified learning experiences. We leverage technology, such as VR and DIY Robo-kits, to create inclusive and accessible platforms that promote critical thinking, problem-solving, and technological literacy.",
  },
  {
    id: 2,
    mainHeading: "Our Vision",
    imageUrl: Vission,
    subDescription:
      "Our vision is to provide an immersive personalized education system tailored to the unique needs of each child through an interactive VR-enabled 3D learning platform. Using virtual and augmented reality, we aim to create an immersive and engaging educational environment that surpasses traditional online learning experiences.",
  },
];

export const awardWiningData = [
  {
    id: "1",
    topsmallHead: "STEAM Minds",
    topHeading: "Winner",
    description:
      "STEAM MINDS has emerged as the winner in the prestigious Provincial Level National Idea Bank (NIB) EdTech Competition, securing the top position not only in 2022 but also repeating the remarkable feat in 2023. Awarded by president of the Islamic Republic of Pakistan Dr Arif Alvi.",
    image: OnlineClasses,
  },
  {
    id: "2",
    topsmallHead: "STEAM Minds",
    topHeading: "Winner",
    description:
      "STEAM MInds has won the TIE competition because of its innovative idea and approach in STEAM education.",
    image: OnlineClasses,
  },
];

export const teamMembersData = [
  {
    id: "1",
    name: "Mehtab Khalid",
    designation: "Chief Exective Officer",
    description:
      "With over 6 years of experience in the startup world and a background in MSCS, Mr. Khalid brings a wealth of knowledge and expertise to STEAM Minds. He is passionate about using technology to improve education and is committed to ensuring that every student has access to quality learning experiences.",
    image: MehtakKhalid, // Replace with actual image path
  },
  {
    id: "2",
    name: "Dr. Akhtar Hussain ",
    designation: "Chief Operation Manager",
    description:
      "As Chief Financial Officer, Dr. Hussain is responsible for managing the financial operations of STEAM Minds. With over 10 years of experience in the business world and a background in social sciences, Dr. Hussain brings a unique perspective to the team.",
    image: AkhtarHussain, // Replace with actual image path
  },
  {
    id: "3",
    name: "Ibrahim Anwar Khalid",
    designation: "Chief Marketing Officer",
    description:
      "With more than 4 years of experience in startups, Mr. Khalid serves as Chief Marketing Officer for STEAM Minds. He is passionate about promoting the company's mission and vision, and is committed to increasing its reach and impact.",
    image: IbrahimKhalid, // Replace with actual image path
  },
  {
    id: "4",
    name: "Nouman Ahmed",
    designation: "Chief Technology Officer",
    description:
      "Engr. Ahmad serves as STEAM Minds' Chief Technology Officer. With a background in Mechatronics and more than 2 years of experience in startups, Engr. Ahmad is responsible for overseeing the development and implementation of the company's technological solutions.",
    image: Nouman, // Replace with actual image path
  },

  {
    id: "5",
    name: "Advisory Board",
    designation: "Combined Team",
    description:
      "STEAM Minds' advisory board is comprised of technical and non-technical members who provide guidance and expertise to the company. They bring a wealth of knowledge and experience from various fields, including education, business, and technology.",
    image: AdvisoryBoard, // Replace with actual image path
  },
  // Add more data samples here...
];

export const achievementsData = [
  {
    id: 1,
    number: "1k+",
    subText: "Students",
  },
  {
    id: 2,
    number: "20+",
    subText: "Trainers",
  },
  {
    id: 3,
    number: "99.9%",
    subText: "Success",
  },
  {
    id: 4,
    number: "100+",
    subText: "Employees",
  },
  {
    id: 5,
    number: "7+",
    subText: "Experience",
  },
];

export const NavbarLinks = [
  { id: 1, name: "Home", path: "/", icon: MobileHomeIcon },
  { id: 2, name: "About Us", path: "/aboutus", icon: MobileAboutIcon },
  {
    id: 3,
    name: "Courses",
    path: "/explore-allcourses",
    icon: MobileCoursesIcon,
  },
  {
    id: 4,
    name: "Ambassadors",
    path: "/steam-ambassadors",
    icon: MobileAmbassadorIcon,
  },
  { id: 5, name: "Blogs", path: "/blogs", icon: MobileBlogIcon },
  {
    id: 6,
    name: "VR Labs",
    path: "http://vrlab.steamminds.org/",
    icon: MobileVRLabs,
  },

  {
    id: 7,
    name: "Our Products",
    path: "/steam-products",
    icon: MobileProducts,
  },
  { id: 8, name: "Contact us", path: "/contactus", icon: MobileContactIcon },
];

export const FooterLinks = [
  {
    id: 1,
    name: "About Us",
    path: "/aboutus",
  },
  {
    id: 2,
    name: "Contact Us",
    path: "/contactus",
  },
  {
    id: 3,
    name: "Ambassadors",
    path: "/steam-ambassadors",
  },

  {
    id: 4,
    name: "Blogs",
    path: "/blogs",
  },
  {
    id: 5,
    name: "NEWS",
    path: "/steam-ambassadors",
  },
];

export const MobileCategoryDescriptionData = [
  {
    id: 1,
    description:
      "Engage kids in exciting science experiments, hands-on-exploration and activities, fostering curiosity and learning. Develop problem-solving skills and critical thinking in young minds with scientific concepts and real-world applications.",
  },
];

export const BlogsData = [
  {
    id: 1,
    title: "What is STEM?",
    Date: "28 jul 2023",
    img: BlogThumbnail,
  },
];

export const ProductDetail = [
  {
    id: 1,
    productName: "STEAM Words",
    ProductDescription:
      "Step into a world of captivating challenges with Word Find, our enthralling crossword puzzle game designed to captivate students from K12. Fuel your brainstorming, foster creativity, and hone critical thinking through this immersive experience. As you navigate intricate word puzzles, you'll fortify your understanding of STEAM concepts, pushing the boundaries of conventional learning. Join us on this journey of discovery and learning beyond limits",
    ProductImage: WordsFind,
    Playstorelink:
      "https://play.google.com/store/apps/details?id=com.developer.steamminds.wordFind",
  },
  {
    id: 2,
    productName: "Kids Drawing",
    ProductDescription:
      "The Kids Drawing game is meticulously crafted to meet the unique needs of kindergarten learners (K5), offering engaging strokes and letter formation activities. Designed with a focus on both K5 and K8 levels, this interactive game equips children with essential skills such as stroke formation, identification of alphabets, numbers, and colors. At its core, the game enhances sensory skills, fostering the development of fine motor control. What sets this game apart is its incorporation of mini-games that allow kids to learn strokes effectively, a crucial aspect for K5 learners. By infusing education with playfulness, this game not only ensures enjoyable learning but also lays the foundation for creative expression and cognitive growth.",
    ProductImage: KidsDrawing,
  },
  {
    id: 3,
    productName: "Mad Minds",
    ProductDescription:
      "Mad Minds (Abacus Integrated) is an innovative educational game strategically designed to nurture mental math skills, a vital component of STEAM education. This unique game incorporates the abacus, a timeless mathematical tool, to enhance affordability and accessibility. By seamlessly blending technology and traditional methods, Mads Mind offers an engaging platform for students to sharpen their mental math abilities. This interactive experience not only strengthens mathematical aptitude but also fosters logical thinking and problem-solving skills. With Mads Mind, learners embark on an enjoyable journey that empowers them to master mental calculations while embracing the STEAM philosophy.",
    ProductImage: MadMinds,
  },
  {
    id: 4,
    productName: "ABC Learning",
    ProductDescription:
      "The ABC Learning Game is a captivating 3D animated experience tailored for kindergarten learners (K5) to embark on an exciting journey into the world of alphabets. Crafted with meticulous care, this game immerses young minds in a virtual school environment where they can explore and master the alphabet. Leveraging our gamified learning approach, the ABC Learning Game transforms education into an adventure, ensuring engagement and effective learning. Through interactive gameplay and captivating visuals, children not only grasp the alphabet but also develop cognitive skills, memory retention, and a love for learning. This immersive and fun-filled journey lays a strong foundation for future academic pursuits while embracing the STEAM philosophy from an early age.",
    ProductImage: Product,
  },
  {
    id: 5,
    productName: "Kids Mathics",
    ProductDescription:
      "The kids Mathics is specially designed for k5 to learn counting and numbers. Kids Mathics is a tailor-made application designed to introduce young learners, from kindergarten to grade 5, to the world of numbers and counting. Through engaging and interactive activities, children embark on an exciting journey to develop a strong foundation in mathematics. This program goes beyond traditional methods, using creative techniques and hands-on experiences to make learning math enjoyable and effective for young minds. With Kids Mathics, children build essential numeracy skills while having fun, setting a solid groundwork for their ongoing academic journey.",
    ProductImage: Product,
  },
];

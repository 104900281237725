import { useMemo } from "react";
import { useGetAboutUsQuery } from "../../features/aboutus/topbanner/aboutusSlice";
import { useGetMissionVissionQuery } from "../../features/aboutus/missionvission/missionvVissionSlice";
import { useGetTeamMemberQuery } from "../../features/aboutus/teammember/teamMemberSlice";
import { useGetCompetationQuery } from "../../features/aboutus/competation/competationSlice";
import { useGetAchievementQuery } from "../../features/aboutus/achievements/achivementsSlice";

export const useGetAboutUs = () => {
  const { data, isSuccess, isLoading } = useGetAboutUsQuery();
  const AboutUsInfo = useMemo(() => {
    const getAboutUsTopBanner = data || [];

    return getAboutUsTopBanner?.map((topData) => ({
      TopBannerId: topData?._id,
      //   TopBannerTitle: topData?.title,
      TopBannerDescription: topData?.shortdescription,
      TopBannerImage: topData?.image,
    }));
  }, [data]);
  return {
    TopBannerData: AboutUsInfo,
    isLoading,
    isSuccess,
  };
};

export const useGetMissionVissionData = () => {
  const { data, isSuccess, isLoading } = useGetMissionVissionQuery();
  const MissionVissionInfo = useMemo(() => {
    const getMissionInformation = data || [];

    return getMissionInformation?.map((MissionVissionInfo) => ({
      InfoId: MissionVissionInfo?._id,
      InfoTitle: MissionVissionInfo?.title,
      InfoDescription: MissionVissionInfo?.description,
      InfoImage: MissionVissionInfo?.image,
    }));
  }, [data]);
  return {
    MissionVission: MissionVissionInfo,
    isLoading,
    isSuccess,
  };
};

export const useGetTeamMemberData = () => {
  const { data, isSuccess, isLoading } = useGetTeamMemberQuery();
  const TeamMemberInfo = useMemo(() => {
    const getTeamMemberInfo = data || [];

    return getTeamMemberInfo?.map((teammember) => ({
      MemberId: teammember?._id,
      MemberImage: teammember?.image,
      MemberName: teammember?.member_name,
      MemberDesignation: teammember?.designation,
      MemberInfo: teammember?.description,
    }));
  }, [data]);
  return {
    TeamMember: TeamMemberInfo,
    isLoading,
    isSuccess,
  };
};

export const useGetCompetationData = () => {
  const { data, isSuccess, isLoading } = useGetCompetationQuery();
  const CompetationInfo = useMemo(() => {
    const getTeamMemberInfo = data || [];

    return getTeamMemberInfo?.map((competation) => ({
      CompetationId: competation?._id,
      CompetationDescription: competation?.description,
    }));
  }, [data]);
  return {
    Competation: CompetationInfo,
    isLoading,
    isSuccess,
  };
};

export const useGetAchievementData = () => {
  const { data, isSuccess, isLoading } = useGetAchievementQuery();
  const AchievementInfo = useMemo(() => {
    const getAchievementInfo = data || [];

    return getAchievementInfo?.map((acheivemnt) => ({
      AchievementId: acheivemnt?._id,
      AchievementDescription: acheivemnt?.shortdescription,
      Achievements: Array.isArray(acheivemnt?.achievement)
        ? acheivemnt?.achievement
        : [acheivemnt?.achievement],
    }));
  }, [data]);
  return {
    Achievement: AchievementInfo,
    isLoading,
    isSuccess,
  };
};

import { Box } from "@mui/system";
import { styled } from "@mui/system";

export const SingleCourseMainContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "30px 60px",
  width: "100%",
  //   height: "470px",
  background: "var(--clr-secondary)",
  "@media (max-width: 400px)": {
    padding: "20px 30px",
  },
}));

export const SingleCourseLeftSide = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  justifyContent: "flex-start",
  width: "55%",
  "@media (max-width: 820px)": {
    width: "100%",
  },
}));

export const SingleCourseLeftSideContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const SingleCourseLeftSideMainHeding = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "55px",
  fontWeight: 700,
  lineHeight: "66px",
  padding: "35px 0px 0px 0px",
  color: "var(--clr-white)",
  "@media (max-width: 540px)": {
    fontSize: "45px",
  },
}));

export const SingleCourseLeftSideSubHeding = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 300,
  lineHeight: "28px",
  width: "90%",
  letterSpacing: "0.4px",
  padding: "15px 0px 0px 0px",
  color: "var(--clr-white)",
  "@media (max-width: 820px)": {
    width: "100%",
  },
}));

export const SingleCourseModuleContainer = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  alignItems: "start",
  flexWrap: "wrap",
  justifyContent: "space-between",
  width: "90%",
  marginTop: "22px",
  "@media (max-width: 1200px)": {
    width: "100%",
  },
  "@media (max-width: 1024px)": {
    width: "70%",
  },
  "@media (max-width: 912px)": {
    width: "70%",
  },
  "@media (max-width: 820px)": {
    width: "80%",
  },
  "@media (max-width: 540px)": {
    width: "65%",
  },
  "@media (max-width: 500px)": {
    width: "100%",
  },
}));

export const SingleCourseModule = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "rgba(160, 155, 196, 0.20)",
  boxShadow: "0px 30px 60px -15px rgba(142.93, 144.03, 188.06, 0.15)",
  borderRadius: 10,
  backdropFilter: "blur(20px)",
  padding: "10px",
  width: "120px",
  color: "white",
  fontSize: "17px",
  fontFamily: "Poppins",
  fontWeight: 400,
  lineHeight: "30px",
  marginBottom: "12px",
}));

export const SingleCourseRightBanner = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  height: "700px",
  left: "64%",
  top: "30%",
  bottom: "300px",
  width: "33%",
  background: "white",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.10)",
  borderRadius: "10px",
  "@media (max-width: 912px)": {
    width: "35%",
    top: "15%",
    left: "62%",
  },
  "@media (max-width: 820px)": {
    display: "none",
  },
}));

export const SingleCourseRightImage = styled(Box)(() => ({
  width: "100%",
  height: "250px",
  objectFit: "cover",
}));

export const SingleCourseDownSection = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: "12px",
}));

export const SingleCourseDemoButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "var(--clr-secondary)",
  padding: "5px",
  fontSize: "18px",
  fontFamily: "Poppins",
  lineHeight: "30px",
  fontWeight: 300,
  color: "var(--clr-white)",
  borderRadius: "5px",
  cursor: "pointer",
}));

export const SingleCourseIncludeSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "12px",
}));

export const IncludeHeading = styled(Box)(() => ({
  display: "flex",
  fontSize: "16px",
  fontFamily: "Poppins",
  fontWeight: 700,
  lineHeight: "30px",
}));

export const IncludeSubDescriptionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "12px",
}));

export const IconTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));
export const IconContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "25px",
  padding: "2px",
  objectFit: "cover",
}));
export const TextContainer = styled(Box)(() => ({
  width: "100%",
  padding: "4px 2px",
  marginLeft: "2px",
  fontFamily: "Poppins",
  fontSize: "13px",
  fontWeight: 300,
  color: "var(--clr-black)",
}));

export const SingleCourseEnrollButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "var(--clr-white)",
  padding: "5px",
  fontSize: "18px",
  fontFamily: "Poppins",
  margin: "4px 20px",
  lineHeight: "30px",
  border: "0.50px #504298 solid",
  fontWeight: 300,
  color: "var(--clr-secondary)",
  borderRadius: "10px",
  cursor: "pointer",
}));

import { useState } from "react";

export const useNavbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [signupModel, setSignupModel] = useState(false);
  const [signInModal, setSignInModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  //   Course Link
  const handleCourseClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // Course Drop Down
  const handleDropDownClose = () => {
    setAnchorEl(null);
  };
  // SignUp Modal
  const handleSignupModal = () => {
    setSignupModel(true);
  };
  // SignIn Modal
  const handleSignInModal = () => {
    setSignInModal(true);
  };
  // Drawer Open Modal
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  // Drawer Close Modal
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return {
    isDrawerOpen,
    signupModel,
    signInModal,
    anchorEl,
    handleCourseClick,
    handleDropDownClose,
    handleSignupModal,
    setSignupModel,
    handleSignInModal,
    setSignInModal,
    handleDrawerOpen,
    handleDrawerClose,
  };
};

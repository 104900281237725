import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const CourseModuleApi = createApi({
  reducerPath: "CourseModuleApi",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }),
  endpoints: (builder) => ({
    getmoduleByCourseId: builder.query({
      query: (id) => ({
        url: `module/course/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetmoduleByCourseIdQuery } = CourseModuleApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const AnnouncementApi = createApi({
  reducerPath: "AnnouncementApi",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }),
  endpoints: (builder) => ({
    getAnnouncement: builder.query({
      query: () => ({
        url: "banner",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAnnouncementQuery } = AnnouncementApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const HomeTestimonialApi = createApi({
  reducerPath: "HomeTestimonialApi",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }),
  endpoints: (builder) => ({
    getHomeTestimonials: builder.query({
      query: () => ({
        url: "hometest",
        method: "GET",
      }),
    }),
    // getCourseTestimonialsById: builder.query({
    //   query: (id) => ({
    //     url: `test/${id}`,
    //     method: "GET",
    //   }),
    // }),
  }),
});

export const { useGetHomeTestimonialsQuery } = HomeTestimonialApi;

import React from "react";
import {
  CourseInfoLearningHeading,
  SingleCourseDetailInfoContainer,
  WelcomeCourseInfoDescription,
  WelcomeCourseInfoHeading,
  WelcomeInfoContainer,
  CourseInfoLearnContainer,
  LearningPointsContainer,
  IconContainer,
  CourseInfoLearningPoints,
  CourseInfoModuleContainer,
  StyledCourseAccordion,
  StyledCourseAccordionSummary,
  StyledCourseAccordionDetails,
  CourseInfoModuleNameContainer,
  CourseInfoModuleName,
  StyledCourseAccordionContainer,
  CourseModuleLecturesInfo,
} from "../../../../styles/ExploreCourseStyling/singleCourse/singlecoursedetailinfo/styleCourseDetailInfo";
import DoneIcon from "@mui/icons-material/Done";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider } from "@mui/material";
import SingleCourseTestimonials from "../singlecoursetestimonials/SingleCourseTestimonials";
import SingleCourseQuestion from "../../singlecoursequestion/SingleCourseQuestion";
// import { useGetLessonByModuleIdQuery } from "../../../../features/coursesLessons/courseLessonSlice";
import { Link, useParams } from "react-router-dom";
import {
  IconTextContainer,
  TextContainer,
} from "../../../../styles/ExploreCourseStyling/singleCourse/singlecoursehero/styleSingleCourseHero";
import {
  CircleIconContainer,
  CourseContentContainer,
  CourseLessonContent,
  CourseLessonName,
  SingleCourseLessonContainer,
} from "../../../../styles/ExploreCourseStyling/singlecoursequestion/styleSingleCourseQuestion";
import VideoIcon from "../../../../assets/images/videoicon.png";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useGetmoduleByCourseIdQuery } from "../../../../features/courses/coursemodule/courseModuleSlice";
const SingleCourseDetailInfo = ({
  name,
  longdescription,
  isLoading,
  learndescription,
}) => {
  const { id } = useParams();
  const { data: ModuleArray = [] } = useGetmoduleByCourseIdQuery(id);

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <SingleCourseDetailInfoContainer>
      <WelcomeInfoContainer>
        <WelcomeCourseInfoHeading>
          Welcome to{" "}
          <span style={{ color: "var(--clr-secondary)" }}> {name}</span> course!
        </WelcomeCourseInfoHeading>
        <WelcomeCourseInfoDescription>
          {longdescription}
        </WelcomeCourseInfoDescription>
      </WelcomeInfoContainer>

      <CourseInfoLearnContainer>
        <CourseInfoLearningHeading>
          What You Will Learn
        </CourseInfoLearningHeading>

        {learndescription.map((learninpoints, index) => (
          <LearningPointsContainer key={index}>
            <IconContainer>
              <DoneIcon style={{ color: "var(--clr-secondary)" }} />
            </IconContainer>
            <CourseInfoLearningPoints>{learninpoints}</CourseInfoLearningPoints>
          </LearningPointsContainer>
        ))}
      </CourseInfoLearnContainer>
      <CourseInfoModuleContainer>
        {ModuleArray.map((item, index) =>
          item?.module?.map((module) => (
            <React.Fragment key={module?._id}>
              <CourseInfoModuleNameContainer>
                <CourseInfoModuleName>{module?.name}</CourseInfoModuleName>
                <CourseModuleLecturesInfo>
                  {module?.countlecture} - {module?.time}
                </CourseModuleLecturesInfo>
              </CourseInfoModuleNameContainer>
              <StyledCourseAccordionContainer>
                <StyledCourseAccordion
                  key={index}
                  expanded={expanded === `panel${module?._id}`}
                  onChange={handleChange(`panel${module?._id}`)}
                >
                  <StyledCourseAccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          background: "var(--clr-secondary)",
                          borderRadius: "50px",
                          marginLeft: "15px",
                          color: "var(--clr-white)",
                          ...(expanded === `panel${module?._id}` && {
                            marginRight: "15px", // adjust marginLeft when expanded
                          }),
                        }}
                      />
                    }
                  >
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        {module?.title}
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          fontFamily: "Poppins",
                          lineHeight: "19px",
                        }}
                      >
                        {module?.description}
                      </p>
                    </div>
                  </StyledCourseAccordionSummary>

                  <StyledCourseAccordionDetails>
                    {module?.lesson?.map((lesson) => (
                      <SingleCourseLessonContainer key={lesson?._id}>
                        <CourseLessonName>{lesson?.name}</CourseLessonName>
                        <CourseContentContainer>
                          <CircleIconContainer>
                            <RadioButtonCheckedIcon
                              sx={{
                                fontSize: "18px",
                                color: "var(--clr-secondary)",
                              }}
                            />
                          </CircleIconContainer>
                          <CourseLessonContent>
                            {lesson?.content}
                          </CourseLessonContent>
                        </CourseContentContainer>
                        {/* <Link
                          to={`/video/${lesson?.lecturevideos.map(
                            (lesson) => lesson?._id
                          )}`}
                        >
                          <IconTextContainer>
                            <IconContainer>
                              <img src={VideoIcon} alt="video" />
                            </IconContainer>
                            <TextContainer>Watch Lectures</TextContainer>
                          </IconTextContainer>
                        </Link> */}
                        {lesson?.lecturevideos.map((video) => (
                          <Link key={video?._id} to={`/video/${video?._id}`}>
                            <IconTextContainer>
                              <IconContainer>
                                <img src={VideoIcon} alt="video" />
                              </IconContainer>
                              <TextContainer>
                                Watch Lecture - {video?.title}
                              </TextContainer>
                            </IconTextContainer>
                          </Link>
                        ))}
                      </SingleCourseLessonContainer>
                    ))}
                  </StyledCourseAccordionDetails>
                </StyledCourseAccordion>
              </StyledCourseAccordionContainer>
              <Divider />
            </React.Fragment>
          ))
        )}
      </CourseInfoModuleContainer>
      {/* Course Testimonials */}
      <SingleCourseTestimonials />
      {/* Frequently Asked Questions About Course */}
      <SingleCourseQuestion />
    </SingleCourseDetailInfoContainer>
  );
};

export default SingleCourseDetailInfo;

import { useMemo } from "react";
import { useGetProductTopBannerQuery } from "../../features/ourproducts/topbanner/ProductTopBannerSlice";
import { useGetProductsQuery } from "../../features/ourproducts/products/productSlice";
export const useGetProductTopBanner = () => {
  const { data, isSuccess, isLoading } = useGetProductTopBannerQuery();
  const ProductTopInfo = useMemo(() => {
    const getProductTopBanner = data || [];

    return getProductTopBanner?.map((topData) => ({
      TopBannerId: topData?._id,
      //   TopBannerTitle: topData?.title,
      TopBannerDescription: topData?.description,
      TopBannerImage: topData?.image,
    }));
  }, [data]);
  return {
    TopBannerData: ProductTopInfo,
    isLoading,
    isSuccess,
  };
};

export const useGetProductsInfo = () => {
  const { data, isSuccess, isLoading } = useGetProductsQuery();
  const ProductsInfo = useMemo(() => {
    const getProductsInformation = data || [];

    return getProductsInformation?.map((product) => ({
      productId: product?._id,
      productName: product?.productname,
      productDescription: product?.description,
      productImage: product?.image,
      productLink: product?.link,
    }));
  }, [data]);
  return {
    Products: ProductsInfo,
    isLoading,
    isSuccess,
  };
};

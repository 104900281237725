import React from "react";
import {
  LogoTextContainer,
  LogoTextIconContainer,
  PaymentDownLeftRightContainer,
  PaymentDownSectionLeftSide,
  PaymentDownSectionRightSide,
  PaymentLogoContainer,
  PaymentProceedButton,
  PaymentRightDownSection,
  PaymentRightHeadingContainer,
  PaymentRightIconContainer,
  PaymentRightSubHeadingContainer,
  PaymentRightTextContainer,
  PaymentSummaryLeftSide,
  PaymentSummaryRightSide,
  TotalPaymentContainer,
} from "../../../../styles/ExploreCourseStyling/proceedtopay/totalcalculation/styleTotalCalculation";
import DeleteIcon from "@mui/icons-material/Delete";
import { Divider } from "@mui/material";
import Logo from "../../../../assets/images/logo.png";
const Calculation = ({ setShowAddPayment }) => {
  const handleProceed = () => {
    setShowAddPayment(true);
  };
  return (
    <div>
      <TotalPaymentContainer>
        <LogoTextIconContainer>
          <LogoTextContainer>
            <PaymentLogoContainer>
              <img
                src={Logo}
                alt="logo"
                style={{ width: "100%", height: "100%" }}
              />
            </PaymentLogoContainer>
            <PaymentRightTextContainer>
              <PaymentRightHeadingContainer>
                STEAM Minds
              </PaymentRightHeadingContainer>
              <PaymentRightSubHeadingContainer>
                Payment Details
              </PaymentRightSubHeadingContainer>
            </PaymentRightTextContainer>
          </LogoTextContainer>
          <PaymentRightIconContainer>
            <DeleteIcon sx={{ color: "var(--clr-red)" }} />
          </PaymentRightIconContainer>
        </LogoTextIconContainer>
        <Divider sx={{ color: "red" }} />
        <PaymentRightDownSection>
          <PaymentDownLeftRightContainer>
            <PaymentDownSectionLeftSide>Course</PaymentDownSectionLeftSide>
            <PaymentDownSectionRightSide>
              Python Premium Course
            </PaymentDownSectionRightSide>
          </PaymentDownLeftRightContainer>
          <PaymentDownLeftRightContainer>
            <PaymentDownSectionLeftSide>Duration</PaymentDownSectionLeftSide>
            <PaymentDownSectionRightSide>12 Weeks</PaymentDownSectionRightSide>
          </PaymentDownLeftRightContainer>
          <PaymentDownLeftRightContainer>
            <PaymentSummaryLeftSide>Summary</PaymentSummaryLeftSide>
            <PaymentSummaryRightSide>$ 270 </PaymentSummaryRightSide>
          </PaymentDownLeftRightContainer>
          <PaymentDownLeftRightContainer>
            <PaymentProceedButton onClick={handleProceed}>
              Click to proceed
            </PaymentProceedButton>
          </PaymentDownLeftRightContainer>
        </PaymentRightDownSection>
      </TotalPaymentContainer>
    </div>
  );
};

export default Calculation;

import React from "react";

import {
  BreadcrumbContainer,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from "../../styles/GeneralComponentStyle/styleGeneralComponent";

export const BreadCrumb = ({ items }) => {
  return (
    <BreadcrumbContainer>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <BreadcrumbLink to={item.to}>{item.label}</BreadcrumbLink>
          {index < items.length - 1 && (
            <BreadcrumbSeparator></BreadcrumbSeparator>
          )}
        </React.Fragment>
      ))}
    </BreadcrumbContainer>
  );
};

export const useFooter = () => {
  const handleFacebook = () => {
    window.open("https://www.facebook.com/STEAMMINDS", "_blank");
  };
  const handleinstagram = () => {
    window.open("https://www.instagram.com/steam.minds/", "_blank");
  };
  const handlelinkedin = () => {
    window.open(
      "https://www.linkedin.com/company/steam-minds/?viewAsMember=true",
      "_blank"
    );
  };
  const handletwitter = () => {
    window.open("https://twitter.com/STEAM_Minds", "_blank");
  };
  return { handleFacebook, handleinstagram, handlelinkedin, handletwitter };
};

import { styled } from "@mui/system";
// import { Box, Button, Typography, Card } from "@mui/material";
import { Box } from "@mui/system";
import ContactBackground from "../../../../src/assets/images/contactusbackground.png";
export const ContactMainContainer = styled(Box)(() => ({
  margin: "0px",
  padding: "0px",
}));

export const ContactMainHeroSection = styled(Box)(() => ({
  // display: "flex",
}));

export const ImageContainer = styled(Box)(() => ({
  // backgroundPosition: "center",
  // backgroundRepeat: "no-repeat",
  // objectFit: "cover",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  height: "470px",
  backgroundImage: `url(${ContactBackground})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "fill",
}));

export const RelativeFormContainer = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  margin: "5px 40px",
  zIndex: 999,
}));

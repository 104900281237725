// import { Checkbox, Modal, Paper, Select, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import LogoImage from "../../../src/assets/images/logo.png";
export const MobileMainContainer = styled(Box)(() => ({
  position: "absolute",
  top: 115,
  left: 0,
  width: "100%",
  height: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  display: "none",
  // margin: "60px 20px",
  background: "var(--clr-secondary)",
}));

export const MobileTopSections = styled(Box)(() => ({
  background: "#504298",
  display: "flex",
  width: "100%",
}));

export const MobileLogoContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "12px",
  width: "70px",
  height: "75px",
  background: "#FFFFFF",
  boxShadow: "0px 2px 18px rgba(0, 0, 0, 0.25)",
  borderRadius: "7px",
  backgroundImage: `url(${LogoImage})`,
  backgroundSize: "contain",
  padding: "4px",
  backgroundPosition: "center",
  // objectFit: "cover",
  backgroundRepeat: "no-repeat",
}));

export const MobileBottomSection = styled(Box)(() => ({
  background: "var(--clr-white)",
  display: "flex",
  width: "100%",
}));

import React from "react";
import {
  AmbassadorContainer,
  AmbassadorsName,
  CustomSubText,
  LeftSection,
  RightSection,
  RightSectionTextContainer,
  RightDescription,
  SteamAmbassadorsMainContainer,
  SteamAmbassadorsMainHeading,
  SteamAmbassadorsTextContainer,
  SubTextContainer,
  RightSubText,
  LeftImageContainer,
} from "../../../styles/SteamAmbassadorsStyling/steamambassadors/styleSteamambassadors";
import { useGetAmbassadorInfo } from "../../../customhooks/ambassador/useAmbassador";
import { CircularProgress } from "@mui/material";
const STEAMAmbassadors = () => {
  const { AmbassadorInfo, isLoading, isSuccess } = useGetAmbassadorInfo();
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (isSuccess) {
    return (
      <>
        <SteamAmbassadorsMainContainer>
          <SteamAmbassadorsTextContainer>
            <SteamAmbassadorsMainHeading>
              Our STEAM Ambassadors
            </SteamAmbassadorsMainHeading>
            <SubTextContainer>
              <CustomSubText>
                Know about the STEAM Mind Brand Ambassadors
              </CustomSubText>
            </SubTextContainer>
          </SteamAmbassadorsTextContainer>
          {AmbassadorInfo.map((ambassadors, index) => (
            <AmbassadorContainer
              key={index}
              style={{
                flexDirection: index % 2 === 0 ? "row" : "row-reverse",
              }}
            >
              <LeftSection>
                <LeftImageContainer>
                  <img
                    src={ambassadors?.ambassadorImage}
                    alt="steamAmbassadors"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </LeftImageContainer>
              </LeftSection>
              <RightSection>
                <RightSectionTextContainer>
                  <AmbassadorsName
                    style={{
                      flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                      textAlign: index % 2 !== 0 ? "end" : null,
                    }}
                  >
                    {ambassadors?.ambassadorName}
                  </AmbassadorsName>
                  <RightSubText
                    style={{
                      flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                      textAlign: index % 2 !== 0 ? "end" : null,
                    }}
                  >
                    {ambassadors?.ambassadordesignation}
                  </RightSubText>
                  <RightDescription>
                    {ambassadors?.ambassadorDescription}
                  </RightDescription>
                </RightSectionTextContainer>
              </RightSection>
            </AmbassadorContainer>
          ))}
        </SteamAmbassadorsMainContainer>
      </>
    );
  }
};

export default STEAMAmbassadors;

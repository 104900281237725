import React from "react";
import CourseHeroSection from "../../components/ExploreCoursesComponents/courseherosection/CourseHeroSection";
import Navbar from "../../components/GlobalComponents/appbar/Navbar";
import AllCourseSection from "../../components/ExploreCoursesComponents/allcoursessection/AllCourseSection";
import Footer from "../../components/GlobalComponents/footer/Footer";

const Courses = () => {
  return (
    <>
      <Navbar />
      <CourseHeroSection />
      <AllCourseSection />
      <Footer />
    </>
  );
};

export default Courses;

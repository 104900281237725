import { Box } from "@mui/system";
import { styled } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
export const SingleCourseQuestionsMainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  margin: "20px 0px",
  "@media (max-width: 912px)": {
    margin: "50px",
    width: "94%",
  },
  "@media (max-width: 540px)": {
    margin: "20px",
  },
}));

export const SingleCourseQuestionsTextContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  margin: "10px 20px",
  "@media (max-width: 912px)": {
    width: "94%",
  },
  "@media (max-width: 820px)": {
    margin: "10px",
  },
  "@media (max-width: 400px)": {
    margin: "0px",
  },
}));

export const SingleCourseQuestionMainHeading = styled(Typography)(() => ({
  fontSize: "30px",
  fontFamily: "Poppins",
  fontWeight: 700,
  lineHeight: "48px",

  color: "#213D39",
  "@media (min-width: 821px) and (max-width: 912px)": {
    fontSize: "39px",
    lineHeight: "38px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    fontSize: "35px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    fontSize: "28px",
  },
  "@media (max-width: 540px)": {
    fontSize: "22px",
  },
  "@media (max-width: 400px)": {
    fontSize: "16px",
    lineHeight: "12px",
  },
}));

export const SubTextContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "912px",
  "@media (min-width: 821px) and (max-width: 912px)": {
    width: "500px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    width: "400px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    width: "300px",
  },
  "@media (max-width: 540px)": {
    width: "280px",
  },
  "@media (max-width: 400px)": {
    width: "280px",
  },
}));

export const CustomSubText = styled(Typography)(() => ({
  fontSize: "20px",
  fontFamily: "Poppins",
  fontWeight: 300,
  lineHeight: "36px",
  marginTop: "3px",
  color: "#686666",
  textAlign: "center",
  "@media (max-width: 540px)": {
    fontSize: "17px",
    lineHeight: "28px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
    lineHeight: "25px",
  },
}));

export const SingleCourseQuestionsAccordianContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  margin: "20px",
  width: "75%",
  "@media (max-width: 912px)": {
    width: "94%",
    margin: "20px 0px",
  },
}));

export const SingleCourseQuestionStyledAccordion = styled(Accordion)(() => ({
  backgroundColor: "#f5f5f5",
  marginBottom: "10px",
  width: "100%",
  borderLeft: "3px solid #504298",
  borderRadius: "5px",
  boxShadow: "0px 0px 10px 0px rgba(80, 66, 152, 0.19)",
}));

export const SingleCourseQuestionStyledAccordionSummary = styled(
  AccordionSummary
)(() => ({
  backgroundColor: "var(--clr-white)",
  color: "var(--clr-black)",
  fontSize: "16px",
  fontFamily: "Poppins",
  fontWeight: 700,
  lineHeight: "25px",
  "@media (max-width: 540px)": {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 600,
  },
}));

export const SingleCourseQuestionStyledAccordionDetails = styled(
  AccordionDetails
)(() => ({
  backgroundColor: "var(--clr-white)",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: 400,
  lineHeight: "20px",
}));

export const SingleCourseLessonContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const CourseLessonName = styled(Box)(() => ({
  marginTop: "18px",
  display: "flex",
  fontSize: "16px",
  fontWeight: 300,
  fontFamily: "Poppins",
}));
export const CourseContentContainer = styled(Box)(() => ({
  display: "flex",
  // justifyContent: "center",
  alignItems: "center",
  marginTop: "10px",
}));
export const CircleIconContainer = styled(Box)(() => ({
  display: "flex",
  width: "18px",
  height: "18px",
}));
export const CourseLessonContent = styled(Box)(() => ({
  display: "flex",
  marginLeft: "6px",
  fontSize: "13px",
}));

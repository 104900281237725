import { Box } from "@mui/system";
import { styled } from "@mui/system";

export const AchievementsMainContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "20px 60px",
  "@media (max-width: 912px)": {
    margin: "20px 40px",
  },
  "@media (max-width: 540px)": {
    margin: "30px",
  },
}));

export const AchievementsTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "20px",
}));

export const AchievementsMainHeading = styled(Box)(() => ({
  fontSize: "43px",
  fontFamily: "Poppins",
  fontWeight: 700,
  lineHeight: "44px",
  color: "#213D39",
  "@media (max-width: 912px)": {
    fontSize: "39px",
    lineHeight: "38px",
  },
  "@media  (max-width: 820px)": {
    fontSize: "35px",
  },
  "@media (max-width: 768px)": {
    fontSize: "28px",
  },
  "@media (max-width: 540px)": {
    fontSize: "22px",
  },
}));

export const SubTextContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px",
  width: "912px",
  "@media (min-width: 821px) and (max-width: 912px)": {
    width: "790px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    width: "700px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    width: "500px",
  },
  "@media (max-width: 540px)": {
    width: "400px",
  },
  "@media (max-width: 400px)": {
    width: "320px",
  },
}));

export const CustomSubText = styled(Box)(() => ({
  fontSize: "20px",
  fontFamily: "Poppins",
  fontWeight: 300,
  lineHeight: "36px",
  marginTop: "8px",
  color: "#686666",
  textAlign: "center",
  "@media (max-width: 540px)": {
    fontSize: "17px",
    lineHeight: "28px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
    lineHeight: "25px",
  },
}));

export const AchievementsInfoContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  width: "100%",
  background: "rgba(80, 66, 152, 0.12)",
  border: `0.5px solid var(--clr-secondary)`,
  borderRadius: "10px",
  marginBottom: "20px",
  boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.10)",
  "@media (max-width: 540px)": {
    flexDirection: "column ",
  },
}));

export const SingleBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  width: "100%",
  height: "120px",
}));
export const NumberTextContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
}));
export const NumberContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  fontFamily: "Poppins",
  fontWeight: "bold",
  fontSize: "20px",
  lineHeight: "30px",
  textAlign: "center",
}));

export const TextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  fontFamily: "Poppins",
  fontWeight: 300,
  fontSize: "14px",
  lineHeight: "24px",
  textAlign: "center",
  "@media (max-width: 820px)": {
    lineHeight: "22px",
  },
}));

import { styled } from "@mui/system";
import { Box, Button, Typography } from "@mui/material";
import CurveImg from "../../../assets/images/curve.png";

export const CategoryContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "30px 60px",
  "@media (max-width: 912px)": {
    margin: "50px",
  },
  "@media (max-width: 540px)": {
    margin: "30px",
  },
}));

export const CategoryTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "20px",
}));

export const CategoryMainHeading = styled(Typography)(() => ({
  fontSize: "43px",
  fontFamily: "Poppins",
  fontWeight: 700,
  lineHeight: "44px",
  color: "#213D39",
  "@media (min-width: 821px) and (max-width: 912px)": {
    fontSize: "39px",
    lineHeight: "38px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    fontSize: "35px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    fontSize: "28px",
  },
  "@media (max-width: 540px)": {
    fontSize: "22px",
  },
  "@media (max-width: 400px)": {
    fontSize: "17px",
  },
}));

export const CustomCurve = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "14px",
  width: "180px",
  height: "22px",
  backgroundImage: `url(${CurveImg})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  objectFit: "cover",
  "@media (max-width: 760px)": {
    width: "120px",
    height: "17px",
  },
  "@media (max-width: 540px)": {
    width: "90px",
    height: "11px",
  },
  "@media (max-width: 400px)": {
    width: "110px",
    height: "7px",
    marginTop: "-5px",
  },
}));

export const SubTextContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "912px",
  "@media (min-width: 821px) and (max-width: 912px)": {
    width: "790px",
  },
  "@media (min-width: 769px) and (max-width: 820px)": {
    width: "700px",
  },
  "@media (min-width: 541px) and (max-width: 768px)": {
    width: "500px",
  },
  "@media (max-width: 540px)": {
    width: "400px",
  },
  "@media (max-width: 400px)": {
    width: "300px",
  },
}));

export const CustomSubText = styled(Typography)(() => ({
  fontSize: "20px",
  fontFamily: "Poppins",
  fontWeight: 300,
  lineHeight: "36px",
  color: "#686666",
  textAlign: "center",
  "@media (max-width: 540px)": {
    fontSize: "17px",
    lineHeight: "28px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
    lineHeight: "25px",
  },
}));

export const SubSmallTextContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "600px",
  marginTop: "20px",
  "@media (max-width: 600px)": {
    width: "500px",
  },
  "@media (max-width: 490px)": {
    width: "330px",
  },
}));

export const CustomSmallSubText = styled(Typography)(() => ({
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "20px",
  color: "#000000",
  textAlign: "center",
  letterSpacing: "0.042em",
  "@media (max-width: 490px)": {
    fontSize: "14px",
    letterSpacing: "0px",
  },
}));

export const TopButtonContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  margin: "10px 50px",
  border: "0.8px solid #D1D1D1",
  borderRadius: "37px",
  padding: "7px 10px",
  "@media (max-width: 490px)": {
    display: "none",
  },
}));
export const MobileTopButtonContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  margin: "5px 50px",
}));
export const MobileTopButton = styled(Button)(() => ({
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Roboto",
  borderRadius: "32px",
  padding: "3px 10px",
  color: "white",
  textTransform: "capitalize",
  fontSize: "13px",
  // marginRight: "14px",
  background: "linear-gradient(0deg, #504298, #504298), #00B8D9",
  boxShadow:
    "0px 2px 8px rgba(117, 131, 142, 0.04), 0px 16px 24px rgba(52, 60, 68, 0.12)",
  display: "none",
  "@media (max-width: 490px)": {
    display: "block",
  },
}));
export const TopButton = styled(Button)(() => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  width: "100%",
  padding: "8px",
  margin: "4px 8px",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#504298",
  border: "0.8px solid #504298",
  borderRadius: "37px",
  textTransform: "capitalize",
}));
